import React, { useState } from 'react';
import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonGrid, IonRow, IonCol, IonSegment, IonSegmentButton, IonLabel, IonToast, isPlatform } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import { MobileHeader, MobileHeaderCondensed, DesktopHeader } from '../../components';
import { BabyForm } from './BabyForm';
import { AccountForm } from './AccountForm';
import './styles.scss';

const Settings: React.FC = () => {
  const { t } = useTranslation(['settings', 'common']);
  const [mode, setMode] = useState<string>('baby');
  const [toast, showToast] = useState<boolean>(false);

  return (
    <IonPage className="settings">
      <MobileHeader title={t('settings:title')} />

      <IonContent fullscreen={true} color="light">
        <MobileHeaderCondensed title={t('settings:title')} />
        <DesktopHeader />

        <main className="ion-padding-vertical">
          {isPlatform('desktop') &&
            <IonHeader className="u-sticky">
              <IonToolbar className="title" color="light">
                <IonTitle size="large">
                  <h1>{t('settings:title')}</h1>
                </IonTitle>
              </IonToolbar>
            </IonHeader>
          }

          <IonGrid>
            <IonRow>
              <IonCol>
                <IonSegment color="primary" className="like-tabs" value={mode} onIonChange={e => setMode(e.detail.value!)}>
                  <IonSegmentButton value="baby">
                    <IonLabel>{t('settings:baby')}</IonLabel>
                  </IonSegmentButton>
                  <IonSegmentButton value="account">
                    <IonLabel>{t('settings:account')}</IonLabel>
                  </IonSegmentButton>
                </IonSegment>
              </IonCol>
            </IonRow>
          </IonGrid>

          {mode === 'baby' ? <BabyForm showToast={showToast} /> : <AccountForm showToast={showToast} />}

        </main>
      </IonContent>
      <IonToast
        isOpen={toast}
        position="top"
        duration={3000}
        onDidDismiss={() => showToast(false)}
        message={t('settings:alert')}
        buttons={['OK']}
      />
    </IonPage>
  );
};

export default Settings;
