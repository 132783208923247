import React from 'react';
import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonGrid, IonRow, IonCol, isPlatform } from '@ionic/react';
import { MobileHeader, MobileHeaderCondensed, DesktopHeader } from '../../components';
// import './styles.scss';

const PrivacyPolicy: React.FC = () => {

  return (
    <IonPage className="aboutpage">
      <MobileHeader title="Privacy" />

      <IonContent fullscreen={true} color="light">
        <MobileHeaderCondensed title="Privacy" />
        <DesktopHeader />

        <main className="ion-padding-vertical">
          {isPlatform('desktop') &&
            <IonHeader className="u-sticky">
              <IonToolbar className="title" color="light">
                <IonTitle size="large">
                  <h1>Privacy</h1>
                </IonTitle>
              </IonToolbar>
            </IonHeader>
          }

          <IonGrid>
            <IonRow>
              <IonCol>
                <h4>Privacy Policy</h4>
                <p><strong>Your privacy is important to us.</strong> This privacy policy has been compiled and updated to better serve those who are concerned with how their ‘Personally Identifiable Information’ (PII) is being used online. PII, as described in U.S. privacy law and information security, is information that can be used on its own or with other information to identify, contact, or locate a person. Please read our privacy policy carefully to get a clear understanding of how we collect, use, protect or otherwise handle your Personally Identifiable Information in accordance with our website and <em>Babykix</em> app.</p>
                <p>What personal information do we collect from the people that visit our website and <em>Babykix</em> app?</p>
                <p>When creating a profile on our app, as appropriate, you may be asked to enter your name, email address, postal code, phone number or other details to help you with your experience.</p>
                <p>When making a donation or ordering materials on our website, as appropriate, you may be asked to enter your name, email address, mailing address, phone number or other details to help you with your experience or send educational materials.</p>
                <p>When do we collect information?</p>
                <p>We collect information from you when you create a profile or enter information on our website or app.</p>
                <p>How do we use your information?</p>
                <p>We may use the information we collect from you when you create a profile, sign up for our newsletter, respond to a survey or marketing communication, or use certain other site features in the following ways:</p>
                <ul>
                  <li>To allow us to better serve you in responding to your customer service requests</li>
                  <li>To personalize your experience and to allow us to deliver the type of content in which you are most interested</li>
                  <li>To administer a promotion, survey or other site feature</li>
                  <li>To ask for ratings and reviews of services or products</li>
                  <li>To follow up with you after correspondence (email or phone inquiries)</li>
                </ul>
                <p>How do we protect your information?</p>
                <p>Our website is scanned on a regular basis for security holes and known vulnerabilities in order to make your visit to our site as safe as possible. We use regular Malware Scanning.</p>
                <p>Your personal information is contained behind secured networks and is only accessible by a limited number of persons who have special access rights to such systems, and are required to keep the information confidential. We implement a variety of security measures when a user enters, submits, or accesses their information to maintain the safety of your personal information.</p>
                <p>All transactions are processed through a gateway provider and are not stored or processed on our servers.</p>
                <p>Third-party disclosure</p>
                <p>We <strong>do not</strong> sell, trade, or otherwise transfer to outside parties your Personally Identifiable Information.</p>
                <p>Third-party links</p>
                <p>Occasionally, at our discretion, we may include or offer third-party products or services on our app. These third-party sites have separate and independent privacy policies. We therefore have no responsibility or liability for the content and activities of these linked sites. Nonetheless, we seek to protect the integrity of our site and welcome any feedback about these sites.</p>
                <p>Europe’s General Data Protection Regulation (GDPR)</p>
                <p>The GDPR took effect on May 25, 2018 and regulates how we interact with consumers in the European Union.&nbsp; We have taken the following steps to ensure GDPR compliance:</p>
                <ul>
                  <li>Updated this privacy policy to be GDPR-compliant.</li>
                  <li>Sent email communication to our email list informing them of these updates.</li>
                  <li>We will report any European data privacy breaches to the EU regulator within 72 hours.</li>
                  <li>Complied with requirements to secure systems and data with best practice security programs.</li>
                  <li>We have the ability to accept requests exercising an individual’s rights to access to, rectification of, deletion of and portability of personal information.</li>
                  <li>We have the ability to accept an individual’s request to restrict or object to the processing of personal data and to object to any decision based solely on automated processing of personal data.</li>
                </ul>
                <p>California Online Privacy Protection Act</p>
                <p>CalOPPA is the first state law in the nation to require commercial websites and online services to post a privacy policy. According to CalOPPA, we agree to the following:</p>
                <ul>
                  <li>Users can visit our app anonymously.</li>
                  <li>You can find this privacy policy on our website, <a href="https://baby-kix.com/">baby-kix.com</a>. You will be notified of any Privacy Policy changes on our Privacy Policy Page.</li>
                  <li>Users can change personal information by logging in to their account.</li>
                </ul>
                <p>How does our website handle Do Not Track signals?</p>
                <p>We honor Do Not Track signals and Do Not Track, plant cookies, or use advertising when a Do Not Track (DNT) browser mechanism is in place.</p>
                <p>We do not allow third-party behavioral tracking.</p>
                <p>COPPA (Children Online Privacy Protection Act)</p>
                <p>When it comes to the collection of personal information from children under the age of 13 years old, the Children’s Online Privacy Protection Act (COPPA) puts parents in control. The Federal Trade Commission, United States’ consumer protection agency, enforces the COPPA Rule, which spells out what operators of websites and online services must do to protect children’s privacy and safety online.</p>
                <p>We do not specifically market to children under the age of 13 years old.</p>
                <p>Fair Information Practices</p>
                <p>The Fair Information Practices Principles form the backbone of privacy law in the United States and the concepts they include have played a significant role in the development of data protection laws around the globe. Understanding the Fair Information Practice Principles and how they should be implemented is critical to comply with the various privacy laws that protect personal information.</p>
                <p>In order to be in line with Fair Information Practices we will notify you via email within 1 business day if there has been a data breach.</p>
                <p>We also agree to the Individual Redress Principle which requires that individuals have the right to legally pursue enforceable rights against data collectors and processors who fail to adhere to the law. This principle requires not only that individuals have enforceable rights against data users, but also that individuals have recourse to courts or government agencies to investigate and/or prosecute non-compliance by data processors.</p>
                <p>CAN SPAM Act</p>
                <p>The CAN-SPAM Act is a law that sets the rules for commercial email, establishes requirements for commercial messages, gives recipients the right to have emails stopped from being sent to them, and spells out tough penalties for violations.</p>
                <p>We collect your email address in order to:</p>
                <ul>
                  <li>Send information, respond to inquiries, and/or other requests or questions</li>
                  <li>Market to our mailing list or continue to send emails to our clients after the original transaction has occurred.</li>
                </ul>
                <p>To be in accordance with CANSPAM, we agree to the following:</p>
                <ul>
                  <li>Not use false or misleading subjects or email addresses.</li>
                  <li>Identify the message as an advertisement in some reasonable way.</li>
                  <li>Include the physical address of our business or site headquarters.</li>
                  <li>Monitor third-party email marketing services for compliance, if one is used.</li>
                  <li>Honor opt-out/unsubscribe requests quickly.</li>
                  <li>Allow users to unsubscribe by using the link at the bottom of each email.</li>
                </ul>
                <p>Last Edited on 5-10-2020</p>
              </IonCol>
            </IonRow>
          </IonGrid>
        </main>
      </IonContent>
    </IonPage>
  );
};

export default PrivacyPolicy;
