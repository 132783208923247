import React, { useContext, useEffect, useCallback } from 'react';
import { IonTabs, IonRouterOutlet, IonTabBar, IonTabButton, IonIcon, IonModal } from '@ionic/react';
import { Route, Redirect } from 'react-router';
import { timerOutline, barChartOutline, settingsOutline, informationCircleOutline } from 'ionicons/icons';
import Counter from '../Counter';
import History from '../History';
import HistoryDetail from '../HistoryDetail';
import Settings from '../Settings';
import About from '../About';
import { LoginModal, WelcomeModal } from '../../modals';
import { Context } from '../../state';
import { Action, SessionHistory } from '../../types';
import { firestore } from '../../firebase';
import './styles.scss';
import PrivacyPolicy from '../Privacy';
import { useTranslation } from 'react-i18next';

const Tabs: React.FC = () => {
  const { dispatch, user, lang, showLogin = false, showWelcome = false, acceptedTerms = false } = useContext(Context);
  const { i18n } = useTranslation();

  const syncHistory = useCallback(
    (user: firebase.User) => {
      return firestore.collection('sessions').where('userId', '==', user.uid).get()
        .then((collection) => {
          if (collection.size) {
            const history: SessionHistory[] = [];

            collection.forEach((doc) => {
              history.push({
                id: doc.id,
                ...doc.data()
              } as SessionHistory)
            })

            dispatch({
              type: Action.StorageSync,
              payload: {
                history: history.sort((a: SessionHistory, b: SessionHistory) => (new Date(b.date)).getTime() - (new Date(a.date)).getTime())
              }
            })
          }
        });
    },
    [dispatch]
  );

  const syncSettings = useCallback(
    (user: firebase.User) => {
      return firestore.doc(`settings/${user.uid}`).get()
        .then((doc) => {
          dispatch({
            type: Action.StorageSync,
            payload: {
              settings: {
                id: doc.id,
                ...doc.data()
              }
            }
          })
        })
    },
    [dispatch]
  );

  useEffect(
    () => {
      if (user) {
        // sync up
        Promise.all([
          syncHistory(user),
          syncSettings(user)
        ])
        .then(() => {})
      }
    },
    [user, syncHistory, syncSettings]
  )

  useEffect(
    () => {
      if (!acceptedTerms && !showWelcome) {
        dispatch({ type: Action.ToggleWelcomeModal, payload: { showWelcome: true } });
      }
    },
    [acceptedTerms, showWelcome, dispatch]
  )

  useEffect(
    () => {
      i18n.changeLanguage(lang);
    },
    [lang, i18n]
  )

  return (
    <>
      <IonTabs>
        <IonRouterOutlet>
          <Route path="/counter" component={Counter} exact={true} />
          <Route path="/history" component={History} exact={true} />
          <Route path="/history/:id" component={HistoryDetail} exact={true} />
          <Route path="/settings" component={Settings} />
          <Route path="/about" component={About} />
          <Route path="/privacy" component={PrivacyPolicy} />
          <Route path="/" render={() => <Redirect to="/counter" />} exact={true} />
        </IonRouterOutlet>
        <IonTabBar slot="bottom">
          <IonTabButton tab="counter" href="/counter" aria-label="Baby kick counter page">
            <IonIcon icon={timerOutline} />
          </IonTabButton>
          <IonTabButton tab="history" href="/history" aria-label="Session history listing page">
            <IonIcon icon={barChartOutline} />
          </IonTabButton>
          <IonTabButton tab="settings" href="/settings" aria-label="Settings page">
            <IonIcon icon={settingsOutline} />
          </IonTabButton>
          <IonTabButton tab="about" href="/about" aria-label="About page">
            <IonIcon icon={informationCircleOutline} />
          </IonTabButton>
        </IonTabBar>
      </IonTabs>

      <IonModal
        isOpen={showLogin}
        onDidDismiss={() => dispatch({ type: Action.ToggleLoginModal, payload: { showLogin: false } })}
      >
        <LoginModal />
      </IonModal>
      <IonModal
        isOpen={showWelcome}
        cssClass="welcome-modal"
        backdropDismiss={false}
        onDidDismiss={() => dispatch({ type: Action.ToggleWelcomeModal, payload: { showWelcome: false } })}
      >
        <WelcomeModal />
      </IonModal>
    </>
  );
};

export default Tabs;
