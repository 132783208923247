import React, { useState, useEffect } from 'react';
import { IonRow, IonCol, IonItem, IonInput, IonText, IonButton, IonLoading, IonAlert } from '@ionic/react';
import { FormState } from '../../types';
import { auth } from '../../firebase';
import { useTranslation } from 'react-i18next';

export const ResetForm: React.FC<{ setMode: any }> = ({ setMode }) => {
  const { t } = useTranslation(['auth', 'common']);
  const [loading, setLoading] = useState<boolean>(false);
  const [valid, setValid] = useState<boolean>(false);
  const [error, setError] = useState<string>();
  const [success, setSuccess] = useState<boolean>(false);
  const [state, setState] = useState<FormState>({
    email: ''
  });

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);

    // do firebase shit
    auth.sendPasswordResetEmail(state.email)
      .then(() => {
        setLoading(false);
        setSuccess(true);
      })
      .catch((error) => {
        setLoading(false);
        setError(error);
      });
  }

  useEffect(
    () => {
      if (state.email) {
        setValid(true);
      } else {
        setValid(false);
      }
    },
    [state]
  )

  return (
    <form onSubmit={onSubmit}>
      <IonLoading isOpen={loading} />
      <IonRow>
        <IonCol className="ion-margin-bottom">
          <IonItem lines="none" className="with-neu">
            <IonInput value={state.email} name="email" type="email" placeholder={t('common:email')} onIonChange={e => setState({ ...state, email: e.detail.value! })} />
          </IonItem>
        </IonCol>
      </IonRow>
      <IonRow className="ion-justify-content-between ion-align-items-center">
        <IonCol size="auto">
          <IonButton disabled={!valid} type="submit" color="purple">{t('auth:reset-password')}</IonButton>
        </IonCol>
        <IonCol size="auto">
          <IonText style={{ cursor: 'pointer' }} color="purple" onClick={() => setMode('login')}>
            {t('auth:go-back')}
          </IonText>
        </IonCol>
      </IonRow>
      <IonAlert
        isOpen={Boolean(error)}
        onDidDismiss={() => setError(undefined)}
        header={t('common:whoops')}
        message={error}
        buttons={['Ok']}
      />
      <IonAlert
        isOpen={success}
        header={t('common:success')}
        message={t('auth:email-check')}
        buttons={[
          {
            text: 'Ok',
            handler: () => {
              setSuccess(false);
              setMode('login')
            }
          }
        ]}
      />
    </form>
  )
}
