import React, { useContext } from 'react';
import { IonContent, IonPage, IonGrid, IonRow, IonCol, IonButton, isPlatform } from '@ionic/react';
import { Plugins } from '@capacitor/core';
import { useTranslation } from 'react-i18next';
import { Context } from '../../state';
import './styles.scss';
import { Action } from '../../types';

const { Browser } = Plugins;
const webMap = {
  privacy: 'https://baby-kix.com/privacy',
}

const WelcomeModal: React.FC = () => {
  const { dispatch } = useContext(Context);
  const { t } = useTranslation(['welcome']);

  const isoWeb = async (handle: keyof typeof webMap) => {
    if (isPlatform('capacitor')) {
      await Browser.open({ url: webMap[handle] });
    } else {
      window.open(webMap[handle], '_blank');
    }
  }

  return (
    <IonPage className="welcome-page">
      <IonContent className="ion-padding" fullscreen={true} color="light">
        <IonGrid>
          <IonRow>
            <IonCol>
              <h2>{t('welcome:title')}</h2>
              <p>{t('welcome:copy')}</p>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonButton expand="block" color="purple" fill="outline" onClick={() => isoWeb('privacy')}>
                {t('welcome:privacy')}
              </IonButton>
            </IonCol>
            <IonCol>
              <IonButton expand="block" color="purple" onClick={() => dispatch({ type: Action.AcceptTerms, payload: { acceptedTerms: true }})}>
                {t('welcome:understand')}
              </IonButton>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
}

export default WelcomeModal;
