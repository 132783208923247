import React, { useState, useContext, useEffect } from 'react';
import { IonRow, IonCol, IonItem, IonInput, IonText, IonButton, IonLoading, IonAlert } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import { SignUpFormState, Action, SessionHistory } from '../../types';
import { auth, firestore } from '../../firebase';
import { Context } from '../../state';

export const SignUpForm: React.FC<{ setMode: any }> = ({ setMode }) => {
  const { t } = useTranslation(['auth', 'common']);
  const { dispatch, history, settings } = useContext(Context);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>();
  const [valid, setValid] = useState<boolean>(false);
  const [state, setState] = useState<SignUpFormState>({
    name: '',
    email: '',
    password: ''
  });

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);

    // do firebase shit
    auth.createUserWithEmailAndPassword(state.email, state.password)
      .then((result) => {
        // wait an close
        if (result.user) {
          const { user } = result;
          user.updateProfile({
            displayName: state.name
          });

          // sync up any local stuff to firebase
          if (history.length) {
            history.forEach((session: SessionHistory) => {
              firestore.doc(`sessions/${session.id}`).set({
                ...session,
                userId: user.uid
              });
            });
          }

          firestore.doc(`settings/${user.uid}`).set({
            ...settings,
            id: user.uid
          });

          setLoading(false);
          dispatch({ type: Action.ToggleLoginModal, payload: { showLogin: false }});
        }
      })
      .catch((error) => {
        console.log(error);
        setError(error);
        setLoading(false);
      });
  }

  useEffect(
    () => {
      if (state.email && state.name && state.password) {
        setValid(true);
      } else {
        setValid(false);
      }
    },
    [state]
  )

  return (
    <form onSubmit={onSubmit}>
      <IonLoading isOpen={loading} />
      <IonRow>
        <IonCol className="ion-margin-bottom">
          <IonItem lines="none" className="with-neu">
            <IonInput value={state.name} name="name" type="text" placeholder={t('common:fullname')} onIonChange={e => setState({ ...state, name: e.detail.value! })} />
          </IonItem>
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol className="ion-margin-bottom">
          <IonItem lines="none" className="with-neu">
            <IonInput value={state.email} name="email" type="email" placeholder={t('common:email')} onIonChange={e => setState({ ...state, email: e.detail.value! })} />
          </IonItem>
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol className="ion-margin-bottom">
          <IonItem lines="none" className="with-neu">
            <IonInput value={state.password} name="password" type="password" placeholder={t('common:password')} onIonChange={e => setState({ ...state, password: e.detail.value! })} />
          </IonItem>
        </IonCol>
      </IonRow>
      <IonRow className="ion-justify-content-between ion-align-items-center">
        <IonCol size="auto">
          <IonButton disabled={!valid} type="submit" color="purple">{t('auth:signup')}</IonButton>
        </IonCol>
        <IonCol size="auto">
          <IonText style={{ cursor: 'pointer' }} color="purple" onClick={() => setMode('login')}>
            {t('auth:has-account')}
          </IonText>
        </IonCol>
      </IonRow>
      <IonAlert
        isOpen={Boolean(error)}
        header={t('common:whoops')}
        message={error}
        buttons={['Ok']}
      />
    </form>
  )
}
