import React, { useState, useContext, useEffect } from 'react';
import { IonRow, IonCol, IonItem, IonInput, IonText, IonButton, IonLoading, IonAlert } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import { LoginFormState, Action, SessionHistory } from '../../types';
import { auth, firestore } from '../../firebase';
import { Context } from '../../state';

export const LoginForm: React.FC<{ setMode: any }> = ({ setMode }) => {
  const { dispatch } = useContext(Context);
  const { t } = useTranslation(['auth', 'common']);
  const [loading, setLoading] = useState<boolean>(false);
  const [valid, setValid] = useState<boolean>(false);
  const [error, setError] = useState<string>();
  const [state, setState] = useState<LoginFormState>({
    email: '',
    password: ''
  });

  const syncHistory = (user: firebase.User) => {
    return firestore.collection('sessions').where('userId', '==', user.uid).get()
      .then((collection) => {
        if (collection.size) {
          const history: SessionHistory[] = [];

          collection.forEach((doc) => {
            history.push({
              id: doc.id,
              ...doc.data()
            } as SessionHistory)
          })

          dispatch({
            type: Action.StorageSync,
            payload: {
              history: history.sort((a: SessionHistory, b: SessionHistory) => (new Date(b.date)).getTime() - (new Date(a.date)).getTime())
            }
          })
        }
      });
  }

  const syncSettings = (user: firebase.User) => {
    return firestore.doc(`settings/${user.uid}`).get()
      .then((doc) => {
        dispatch({
          type: Action.StorageSync,
          payload: {
            settings: {
              id: doc.id,
              ...doc.data()
            }
          }
        })
      })
  }

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);

    // do firebase shit
    auth.signInWithEmailAndPassword(state.email, state.password)
      .then((result) => {
        setTimeout(() => {
          if (result.user) {
            Promise.all([
              syncHistory(result.user),
              syncSettings(result.user)
            ]).then(() => {
              setLoading(false);
              dispatch({ type: Action.ToggleLoginModal, payload: { showLogin: false } });
            })
          } else {
            setLoading(false);
            dispatch({ type: Action.ToggleLoginModal, payload: { showLogin: false } });
          }
        }, 1000);
      })
      .catch((error) => {
        setLoading(false);
        setError(error);
      });
  }

  useEffect(
    () => {
      if (state.email && state.password) {
        setValid(true);
      } else {
        setValid(false);
      }
    },
    [state]
  )

  return (
    <form onSubmit={onSubmit}>
      <IonLoading isOpen={loading} />
      <IonRow>
        <IonCol className="ion-margin-bottom">
          <IonItem lines="none" className="with-neu">
            <IonInput value={state.email} name="email" type="email" placeholder={t('common:email')} onIonChange={e => setState({...state, email: e.detail.value! })}/>
          </IonItem>
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol className="ion-margin-bottom">
          <IonItem lines="none" className="with-neu">
            <IonInput value={state.password} name="password" type="password" placeholder={t('common:password')} onIonChange={e => setState({ ...state, password: e.detail.value! })}/>
          </IonItem>
        </IonCol>
      </IonRow>
      <IonRow className="ion-justify-content-between ion-align-items-center">
        <IonCol size="auto">
          <IonButton disabled={!valid} type="submit" color="purple">{t('common:login')}</IonButton>
        </IonCol>
        <IonCol size="auto">
          <IonText style={{ cursor: 'pointer' }} color="purple" onClick={() => setMode('forgot')}>
            {t('auth:forgot')}
          </IonText>
        </IonCol>
      </IonRow>
      <IonRow className="ion-justify-content-between ion-align-items-center">
        <IonCol size="auto">
          <IonText style={{ cursor: 'pointer' }} color="purple" onClick={() => setMode('signup')}>
            {t('auth:create-account')}
          </IonText>
        </IonCol>
      </IonRow>
      <IonAlert
        isOpen={Boolean(error)}
        header={t('auth:whoops')}
        message={error}
        buttons={['Ok']}
      />
    </form>
  )
}
