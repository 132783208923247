import React, { useContext } from 'react';
import { IonGrid, IonRow, IonCol, IonItem, IonInput, IonLabel, IonDatetime, IonButton, IonSegment, IonSegmentButton } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import { Context } from '../../state';
import { Action, Settings } from '../../types';

interface BabyFormProps {
  showToast: (e: React.SetStateAction<boolean>) => void
}

export const BabyForm: React.FC<BabyFormProps> = ({ showToast }) => {
  const { dispatch, settings } = useContext(Context);
  const { t } = useTranslation(['settings', 'common']);

  const update = (key: keyof Settings, value: any) => {
    dispatch({ type: Action.UpdateSetting, payload: { key, value } })
  }

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    showToast(true);
  }

  return (
    <form onSubmit={onSubmit}>
      <IonGrid className="main">
        <IonRow>
          <IonCol>
            <IonItem lines="none" className="with-neu">
              <IonInput
                placeholder={t('settings:baby-name')}
                type="text"
                value={settings?.name}
                onIonChange={e => update('name', e.detail.value)}
              />
            </IonItem>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol>
            <IonItem lines="none" className="with-neu">
              <IonLabel>{t('settings:due-date')}</IonLabel>
              <IonDatetime
                displayFormat="MM/DD/YY"
                placeholder={t('settings:select-date')}
                value={settings?.dueDate}
                onIonChange={e => update('dueDate', e.detail.value)}
              />
            </IonItem>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol>
            <IonSegment className="with-neu" value={settings?.gender || 'girl'} onIonChange={e => update('gender', e.detail.value)}>
              <IonSegmentButton value="boy">
                <IonLabel>{t('settings:boy')}</IonLabel>
              </IonSegmentButton>
              <IonSegmentButton value="girl">
                <IonLabel>{t('settings:girl')}</IonLabel>
              </IonSegmentButton>
              <IonSegmentButton value="surprise">
                <IonLabel>{t('settings:surprise')}</IonLabel>
              </IonSegmentButton>
            </IonSegment>
          </IonCol>
        </IonRow>

        <IonRow>
          <IonCol>
            <IonButton expand="block" fill="outline" color="purple" type="submit">
              {t('common:save')}
            </IonButton>
          </IonCol>
        </IonRow>
      </IonGrid>
    </form>
  );
}
