import React, { useContext, useState, useEffect } from 'react';
import { IonGrid, IonRow, IonCol, IonItem, IonInput, IonButton, IonLoading, IonAlert, IonText, IonSelect, IonSelectOption, IonLabel } from '@ionic/react';
import { Context } from '../../state';
import { Action } from '../../types';
import { auth } from '../../firebase';
import { useTranslation } from 'react-i18next';

interface AccountFormProps {
  showToast: (e: React.SetStateAction<boolean>) => void
}

interface EditableUser {
  displayName?: string;
  email: string;
  password?: string;
}

const availableLanguages = [
  { name: 'English', value: 'en' },
  { name: 'Spanish', value: 'es' },
];

export const AccountForm: React.FC<AccountFormProps> = ({ showToast }) => {
  const { dispatch, user, lang } = useContext(Context);
  const { t } = useTranslation(['settings', 'common', 'dialogs']);
  const [loading, setLoading] = useState<boolean>(false);
  const [confirm, setConfirm] = useState<boolean>(false);
  const [clear, setClear] = useState<boolean>(false);
  const [error, setError] = useState<{ code: string; message: string }>();
  const [form, setForm] = useState<EditableUser>({
    displayName: user?.displayName || '',
    email: user?.email || '',
    password: ''
  })

  useEffect(
    () => {
      if (user) {
        setForm({
          displayName: user.displayName || '',
          email: user.email || '',
        })
      }
    },
    [user]
  )

  if (!user) {
    return (
      <IonGrid className="main">
        <IonRow>
          <IonCol className="ion-text-center">
            <IonItem lines="none">
              <IonLabel>{t('settings:language')}</IonLabel>
              <IonSelect value={lang} onIonChange={e => dispatch({ type: Action.ChangeLanguage, payload: { lang: e.detail.value } })}>
                {availableLanguages.map((lang: any, index: number ) => (
                  <IonSelectOption key={index} value={lang.value}>{lang.name}</IonSelectOption>
                ))}
              </IonSelect>
            </IonItem>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol className="ion-text-center">
            <IonText>
              <p>{t('settings:not-logged-in')}</p>
              <IonButton onClick={() => dispatch({ type: Action.ToggleLoginModal, payload: { showLogin: true } })}>
                {t('settings:login-signup')}
              </IonButton>
            </IonText>
          </IonCol>
        </IonRow>
      </IonGrid>
    )
  }

  const update = (key: keyof EditableUser, value: any) => {
    setForm({
      ...form,
      [key]: value
    })
  }

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);

    const promises = [];

    if (form.displayName !== user.displayName) {
      // update profile
      promises.push(user.updateProfile({ displayName: form.displayName }));
    }

    if (form.email !== user.email) {
      // update email
      promises.push(user.updateEmail(form.email));
    }

    if (form.password) {
      // update password
      promises.push(user.updatePassword(form.password));
    }

    Promise.all(promises)
      .then(() => {
        setLoading(false);
        dispatch({ type: Action.SetUser, payload: { user } });
        showToast(true);
      })
      .catch((error) => {
        setLoading(false);
        setError(error);
      })
  }

  return (
    <>
      <form onSubmit={onSubmit}>
        <input id="password" style={{ display: 'none' }} type="password" name="password"></input>

        <IonGrid className="main">
          <IonRow>
            <IonCol>
              <IonItem lines="none" className="with-neu">
                <IonInput
                  placeholder={t('common:fullname')}
                  type="text"
                  name="displayName"
                  value={form.displayName}
                  onIonChange={e => update('displayName', e.detail.value!)}
                />
              </IonItem>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonItem lines="none" className="with-neu">
                <IonInput
                  placeholder={t('common:email')}
                  type="email"
                  name="email"
                  value={form.email}
                  onIonChange={e => update('email', e.detail.value!)}
                />
              </IonItem>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonItem lines="none" className="with-neu">
                <IonInput
                  placeholder={t('settings:new-password')}
                  type="password"
                  name="new-password"
                  autocomplete="off"
                  value={form.password}
                  onIonChange={e => update('password', e.detail.value!)}
                />
              </IonItem>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol className="ion-text-center">
              <IonItem lines="none" className="with-neu">
                <IonLabel>{t('settings:language')}</IonLabel>
                <IonSelect value={lang} onIonChange={e => dispatch({ type: Action.ChangeLanguage, payload: { lang: e.detail.value } })}>
                  {availableLanguages.map((lang: any, index: number) => (
                    <IonSelectOption key={index} value={lang.value}>{lang.name}</IonSelectOption>
                  ))}
                </IonSelect>
              </IonItem>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonButton type="submit" expand="block" fill="outline" color="purple">
                {t('settings:update')}
              </IonButton>
            </IonCol>
          </IonRow>
        </IonGrid>
      </form>

      <IonGrid>
        <IonRow>
          <IonCol>
            <IonText color="danger">
              <div className="ion-padding-bottom">
                <h2>{t('settings:danger')}</h2>
              </div>
            </IonText>
            <IonButton fill="outline" color="danger" size="small" onClick={() => setConfirm(true)}>
              {t('settings:logout')}
            </IonButton>
            <IonButton fill="clear" color="dark" size="small" onClick={() => setClear(true)}>
              {t('settings:clear')}
            </IonButton>
          </IonCol>
        </IonRow>
      </IonGrid>

      <IonLoading isOpen={loading}/>

      <IonAlert
        isOpen={error && error.code === 'auth/requires-recent-login' ? true : false}
        onDidDismiss={() => setError(undefined)}
        header={t('common:error')}
        message={t('dialogs:error-recent-login')}
        buttons={[
          {
            text: t('common:login'),
            handler: () => {
              dispatch({ type: Action.ToggleLoginModal, payload: { showLogin: true } });
            }
          }
        ]}
      />

      <IonAlert
        isOpen={error && error.code !== 'auth/requires-recent-login' ? true : false}
        onDidDismiss={() => setError(undefined)}
        header={t('common:error')}
        message={error?.message}
        buttons={[t('common:try-again')]}
      />

      <IonAlert
        isOpen={confirm}
        onDidDismiss={() => setConfirm(false)}
        header={t('common:confirm')}
        subHeader={t('dialogs:logout-heading')}
        buttons={[
          {
            text: t('common:yes'),
            handler: () => {
              auth.signOut();
              dispatch({ type: Action.SetUser, payload: { user: null } })
            }
          },
          {
            text: t('common:no'),
            role: 'cancel'
          }
        ]}
      />

      <IonAlert
        isOpen={clear}
        onDidDismiss={() => setClear(false)}
        header={t('common:confirm')}
        subHeader={t('dialogs:history-clear-heading')}
        message={t('dialogs:history-clear-copy')}
        buttons={[
          {
            text: t('common:yes'),
            handler: () => {
              if (user) {
                auth.signOut();
              }
              dispatch({ type: Action.Reset })
            }
          },
          {
            text: t('common:no'),
            role: 'cancel'
          }
        ]}
      />
    </>
  );
}
