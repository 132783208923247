import React from 'react';
import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonGrid, IonRow, IonCol, isPlatform, IonText, IonIcon } from '@ionic/react';
import { Plugins } from '@capacitor/core';
import { logoGithub, logoTwitter, globe } from 'ionicons/icons';
import { useTranslation } from 'react-i18next';
import { MobileHeader, MobileHeaderCondensed, DesktopHeader } from '../../components';
import { version } from '../../../package.json';
import './styles.scss';

const { Browser } = Plugins;
const webMap = {
  privacy: 'https://baby-kix.com/privacy',
  portfolio: 'https://francovaldes.dev/',
  github: 'https://github.com/fvaldes33',
  twitter: 'https://twitter.com/francoxavier33',
  ionic: 'https://ionicframework.com/',
  react: 'https://reactjs.org/',
  union: 'https://union.co/',
  capacitor: 'https://capacitor.ionicframework.com/'
}
const year = (new Date()).getFullYear();

const AboutPage: React.FC = () => {
  const { t } = useTranslation(['about']);

  const isoWeb = async (handle: keyof typeof webMap) => {
    if (isPlatform('capacitor')) {
      await Browser.open({ url: webMap[handle] });
    } else {
      window.open(webMap[handle], '_blank');
    }
  }

  return (
    <IonPage className="aboutpage">
      <MobileHeader title={t('about:title')} />

      <IonContent fullscreen={true} color="light">
        <MobileHeaderCondensed title={t('about:title')} />
        <DesktopHeader />

        <main className="ion-padding-vertical">
          {isPlatform('desktop') &&
            <IonHeader className="u-sticky">
              <IonToolbar className="title" color="light">
                <IonTitle size="large" color="dark">
                  <h1>{t('about:title')}</h1>
                </IonTitle>
              </IonToolbar>
            </IonHeader>
          }

          <IonGrid>
            <IonRow>
              <IonCol>
                <IonText color="dark">
                  <h2>{t('about:why')}</h2>
                  <p>{t('about:why-text')}</p>
                </IonText>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                <IonText color="dark">
                  <h2>{t('about:privacy')}</h2>
                  <p>{t('about:privacy-text')}</p>
                  <p><IonText color="purple" onClick={() => isoWeb('privacy')}>{t('about:privacy-cta')}</IonText></p>
                </IonText>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                <IonText color="dark">
                  <h2>{t('about:author')}</h2>
                  <p>{t('about:author-text-1')} <IonText color="purple" onClick={() => isoWeb('union')}>Union</IonText> {t('about:author-text-2')} <IonText color="purple" onClick={() => isoWeb('ionic')}>Ionic</IonText>, <IonText color="purple" onClick={() => isoWeb('react')}>React</IonText> &amp; <IonText color="purple" onClick={() => isoWeb('capacitor')}>Capacitor</IonText>.</p>
                </IonText>

                <IonIcon color="dark" size="large" icon={globe} onClick={() => isoWeb('portfolio')} />
                <IonIcon color="dark" size="large" icon={logoTwitter} onClick={() => isoWeb('twitter')} />
                <IonIcon color="dark" size="large" icon={logoGithub} onClick={() => isoWeb('github')} />

                <IonText color="dark">
                  <p><small><strong>&copy; {year} Appvents, LLC. All rights reserved.</strong><br />Version {version}</small></p>
                </IonText>
              </IonCol>
            </IonRow>
          </IonGrid>
        </main>
      </IonContent>
    </IonPage>
  );
};

export default AboutPage;
